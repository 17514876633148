import { getTime } from 'date-fns'
import { useMemo } from 'react'

export function HelloWorld({ name }: { name: string }) {
    const date = useMemo(() => new Date(), [])
    return (
        <p>
            Hello {name} World @ {getTime(date)}
        </p>
    )
}
